import admin from './rbac/admin';
import tenantAdmin from './rbac/tenant-admin';
import user from './rbac/user';
import {
    IRBACPermissionStatement,
    IRBACRole,
    IRBACRoleConfig
} from "../../types";


export const rbacCacheKey = '-pac-app-rbac';

export const roleToCogntioGroupMap: { [key: string]: string } = {
    Admin: '^admin',
    PowerUser: '^power_user$',
    PowerUserReadOnly: '^power_user_readonly$',
    TenantAdmin: '^tenant_admin',
    AppAdmin: '^app_admin',
    ServiceAdmin: '^service_admin',
    UserAdmin: '^user_admin',
    User: '^us-west-2_',
};

export type RbacDocumentType = {
    roles: IRBACRole[],
    routes: { [key: string]: any },
    policies: { name: string }[],
    statements: IRBACPermissionStatement[],
    permissions: IRBACRoleConfig[]
}


const roleConfigs: IRBACRoleConfig[] = []

export const defaultRbacDocument: RbacDocumentType = {
    roles: [
        {name: 'Admin'},
        {name: 'PowerUser'},
        {name: 'PowerUserReadOnly'},
        {name: 'TenantAdmin'},
        {name: 'AppAdmin'},
        {name: 'ServiceAdmin'},
        {name: 'UserAdmin'},
        {name: 'User'},
    ],
    policies: [],
    statements: [],
    routes: [
        {
            path: '/applications',
            httpMethod: 'GET',
            actionName: 'ListApplications',
        },
        {
            path: '/applications/add',
            httpMethod: 'POST',
            actionName: 'CreateApplication',
        },
        {
            path: '/applications/{id}',
            httpMethod: 'GET',
            actionName: 'GetApplication',
        },
        {
            path: '/applications/{id}',
            httpMethod: 'PUT',
            actionName: 'UpdateApplication',
        },
    ],
    permissions: roleConfigs
        .concat(admin)
        .concat(user)
        .concat(tenantAdmin)
};
