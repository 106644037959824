import {useAuthenticator} from "@aws-amplify/ui-react";
import {PlatformLoader} from "@flexinet/ui-components";
import {PortalDashboard} from "../components/PortalDashboard";

export const IndexPage = () => {
    const {user} = useAuthenticator();

    if (!user) {
        return <PlatformLoader message="Loading dashboard...Please wait!"/>;
    }

    return <PortalDashboard/>;
};
