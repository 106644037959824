import {AiOutlineArrowUp} from 'react-icons/ai';
import {TbGauge} from "react-icons/tb";
import {DashboardWidgetBuilder} from "./DashboardWidgetBuilder.tsx";
import {WidgetType} from "../../types.ts";

export const SupportWidget = () => {
    const widget: WidgetType = {
        title: 'Support Cases',
        dataPoints: [
            {
                value: 1,
                label: 'Open',
                color: 'blue'
            },
            {
                value: 3,
                label: 'Resoloved',
                color: 'green'
            },
        ],
        metrics: [
            {
                value: 15,
                unit: 'Hours',
                label: 'Average Resolution Time',
                color: 'black',
                insight: {
                    icon: <AiOutlineArrowUp color="blue"/>,
                    label: 'improving month over month',
                    color: 'black',
                },
                visible: true
            },
        ],
        actionItems: [
            {
                label: 'Open Support Case',
                description:
                    'Use Atlassian to submit question or send help request',
                icon: <TbGauge size={16} stroke={1.5}/>,
                visible: true,
            },
        ],
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
