import {ReactNode} from 'react';
import PropTypes from 'prop-types';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Navigate} from "react-router-dom";


export const RequireAuth = ({children}: { children: ReactNode }) => {
    const {authStatus} = useAuthenticator();
    if (authStatus !== 'authenticated') {
        return <Navigate to="/"/>;
    }
    return children;
};

RequireAuth.propTypes = {
    children: PropTypes.node,
};
