import {useEffect} from 'react';

import {Auth} from 'aws-amplify';

import {Center} from '@mantine/core';
import {PlatformLoader} from '@flexinet/ui-components';

export const SignOutPage = () => {

    useEffect(() => {
        const signOutAndRedirect = async () => {

            localStorage.setItem('amplify-signin-with-hostedUI', 'false');

            Auth.signOut()
                .then(() => {
                    window.location.href = '/';
                })
                .catch((err) => {
                    console.debug('IN SIGN OUT PAGE. SIGN OUT EXCEPTION', err);
                });
        }

        signOutAndRedirect()
    }, []);


    return (
        <Center>
            <PlatformLoader message={'Signing out...Please, wait!'}/>
        </Center>
    );
};
