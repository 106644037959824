import {
    Center,
    Group,
    Stack
} from '@mantine/core';
import {FlexiButton} from "@flexinet/ui-components";
import {ServicePlan} from "../types.ts";
import {ServicePlanComponent} from "./ServicePlanComponent.tsx";
import {useAuthenticator} from "@aws-amplify/ui-react";


export const ServicePlansComponent = ({plans}: { plans: ServicePlan[] }) => {

    const {user} = useAuthenticator()

    return (
        <Center>
            <Stack>
                <Group style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                    {plans.map(plan => {
                        return <Stack>
                            <ServicePlanComponent key={plan.id} plan={plan}/>
                            {user ? (plan.current ? <FlexiButton disabled={true}>Current Plan</FlexiButton> :
                                <FlexiButton>Upgrade</FlexiButton>) : null}
                        </Stack>
                    })}
                </Group>
            </Stack>
        </Center>
    );
};
