import {Navigate} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";

import {PlatformLoader} from "@flexinet/ui-components";
import {ProductsComponent} from "../components/ProductsComponent.tsx";

export const ProductsPage = () => {

    const {authStatus} = useAuthenticator();

    switch (authStatus) {
        case "authenticated":
            return <Navigate to={'/dashboard'}/>;
        case "unauthenticated":
            return <ProductsComponent/>
            break;
        case "configuring":
            return <PlatformLoader message={'Loading...Please, wait'}/>
    }


};
