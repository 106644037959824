import {Navigate} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";

import {PlatformLoader} from "@flexinet/ui-components";
import {AboutComponent} from "../components/AboutComponent.tsx";

export const AboutPage = () => {

    const {authStatus} = useAuthenticator();

    switch (authStatus) {
        case "authenticated":
            return <Navigate to={'/dashboard'}/>;
        case "unauthenticated":
            return <AboutComponent/>
            break;
        case "configuring":
            return <PlatformLoader message={'Loading...Please, wait'}/>
    }


};
